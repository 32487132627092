<template>
    <div class="content-page">
        <div class="content-nav">
            <el-breadcrumb class="breadcrumb" separator="/">
                <el-breadcrumb-item>奇妙海岛-题库</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="operation-nav">
                <router-link to="/dashboard/qmhd/qmhd_add">
                    <el-button type="primary" icon="plus" size="small">添加题库</el-button>
                </router-link>
            </div>
        </div>
        <div class="content-main">
          
            <div class="form-table-box">
                <el-table :data="tableData" style="width: 100%" stripe>
                    <!-- <el-table-column prop="id" label="ID" width="120"></el-table-column> -->
                    <el-table-column prop="question_no" label="题号" width="180">
                       
                    </el-table-column>
                        
                    <el-table-column prop="conditions" label="题目条件" width="300">
                        <template scope="scope">
                            <div v-for="item in scope.row.conditions">条件{{item.condition_no}}：{{item.condition}}|{{item.conditionTip1}}|{{item.conditionTip2}}|{{item.conditionTip3}}</div>
                        </template>
                    </el-table-column>

                    <el-table-column label="操作">
                        <template scope="scope">
                            <el-button size="small" @click="handleRowEdit(scope.$index, scope.row)">编辑</el-button>
                            <el-button size="small" plain type="danger" @click="handleRowDelete(scope.$index, scope.row)">删除
                            </el-button>
                        </template>
                    </el-table-column>

                </el-table>
            </div>
            <div class="page-box">
                <el-pagination @current-change="handlePageChange" :current-page="page" :page-size="size"
                               layout="total, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        data() {
            return {
                page: 0,
				size:10,
                total: 0,
                tableData: [],
                pIndex: 0,
                num: 0
            }
        },
        methods: {
            test(){
                console.log(this.tableData);
            },
            
            handlePageChange(val) {
                this.page = val;
                let nIndex = this.pIndex;
                if (nIndex == 0) {
                    this.getList();
                }
            },
            handleRowEdit(index, row) {
                var id = row._id;

                var ritem = null;
                for(var i = 0;i!=this.tableData.length;i++)
                {
                    if(this.tableData[i]._id==id)
                    {
                        ritem = this.tableData[i];
                    }
                }
                if(!!ritem)
                {
                    this.$router.push({name: 'qmhd_addQuestion', query: {id: row._id,questionInfo:ritem}});
                }
            },
            handleRowDelete(index, row) {

                this.$confirm('确定要删除?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                let that = this;
                console.log("row._id is " + row._id);
                that.axios.post('dayquestion/deleteQMHDQuestion', {id: row._id}).then((response) => {
                    if (response.data.code > 0) {
                        that.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                        that.getQuestions();
                    }
                })
                }).catch(() => {
//                    this.$message({
//                        type: 'info',
//                        message: '已取消删除'
//                    });
                });
            },
            getQuestions() {
                let token = localStorage.getItem('token') || '';
                console.log("token is ",token);
                this.axios.get('dayquestion/getQMHDQuestionList',{"token": token}).then((response) => {

                    console.log("获取题库列表。。。");
                    console.log(response.data);
                    this.tableData = response.data.data
                    //  this.page = response.data.data.currentPage
                    // this.total = response.data.data.count
                })
            }
        },
        components: {},
        mounted() {
            this.getQuestions();
        }
    }

</script>

<style scoped>
    .sort-width{
        width: 90px;
    }
    .right{
        float: right;
    }
    .form-inline {
        margin-top: 2px;
        height: 40px;
        margin-right: 20px;
    }

    .block {
        margin-bottom: 10px;
        height:42px;
        display: flex;
        align-items: center;
        justify-content:space-between;
    }

    .active {
        border-color: #ff4949;
        color: #ff4949;
    }

    .marginRight{
        margin-right: 20px;
    }
</style>
